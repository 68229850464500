import { Context } from '@nuxt/types'
import { SearchContext, SearchFilters } from './schemas'
import { sanitize } from '../../utility/sanitize'

export function setLinkHeader(
  searchContext: SearchContext,
  res: Context['res'],
) {
  if (!searchContext.results?.campsites?.length) {
    return
  }
  const campsite = searchContext.results.campsites[0]
  const photo = campsite.primaryPhoto
  if (!photo) {
    return
  }
  res.setHeader(
    'Link',
    `</_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${photo.url.masterImage}>;
    rel="preload";
    as="image";
    fetchpriority="high";`.replace(/\s+/g, ' '),
  )
}

export function setSearchXkeyHeaders(
  searchContext: SearchContext,
  res: Context['res'],
) {
  const slugs = searchContext.results?.campsites?.length
    ? searchContext.results.campsites
      .map((cs) => cs.slug)
      .map((slug) => sanitize(slug))
      .join(' ')
    : ''
  if (!slugs) return
  const currentXkey = res.getHeader('xkey')
  const newXkey =
    currentXkey === undefined ? slugs : `${currentXkey.toString()} ${slugs}`
  res.setHeader('xkey', newXkey)
}

export function httpHeadersCache(ctx: Context) {
  const { route, $config } = ctx
  const isPriceRelated = 'arrive' in route.query || 'depart' in route.query
  const cacheTime = isPriceRelated
    ? $config.priceCacheTime
    : $config.nonPriceCacheTime
  const graceTime = isPriceRelated
    ? $config.priceGraceTime
    : $config.nonPriceGraceTime
  return {
    'Cache-Control': `max-age=${cacheTime}, public`,
    Grace: `${graceTime}`,
  }
}

export function doRedirect(
  url: string,
  statusCode: number,
  res: Context['res'],
) {
  if (url) {
    res.writeHead(statusCode, { Location: url })
  } else {
    res.writeHead(statusCode)
  }
  res.end()
}

export function areHierarchiesDifferent(
  searchContext: SearchContext,
  searchFilters: SearchFilters,
) {
  return (
    searchContext &&
    searchContext.results &&
    searchFilters.hierarchyPath &&
    searchContext.results.hierarchy &&
    searchContext.results.hierarchy !== searchFilters.hierarchyPath
  )
}

export function redirectOnMachineRedirecting(
  searchContext: SearchContext,
  res: Context['res'],
  fullPath: string,
) {
  const hierarchyPath = searchContext.filters.hierarchyPath || ''
  const redirectToPath = searchContext.redirectToPath || ''
  const redirectPath = searchContext.results?.seoRedirect
    ? searchContext.results.seoRedirect
    : fullPath.replace(hierarchyPath, redirectToPath)
  doRedirect(redirectPath, 301, res)
}
