import { parseSearchFiltersToUrl } from '~/apps/pu-links/domain/SearchUrl'
import { getLangFallbackUrl } from '~/lang/utils/langfetch'
import { getTransformObject } from '~/shared/photos/transforms'
import { getWKTPointFromGeoPoint } from '~/utility/geo/GeoLocation'
import { getPointFromStringOrDefault } from '~/utility/geo/latLng'
import { keysToCamel } from '~/utility/toCamel'
import { Logger, useGtmLogRequest } from '../../../composables/useGtmLogRequest'
import {
  CampsiteSearchResult,
  Photo,
  PhotoTransforms,
  SearchCampsitesResults,
  SearchFilters,
  campsiteSearchResultSchema,
} from '../schemas'

export class FetchCampsiteSearchService {
  constructor(private fetchOptions?: any) {}

  async search(
    langCode: string,
    filters: SearchFilters,
    logger: Logger,
    searchUrl?: string,
    fetchOptions?: any,
  ): Promise<SearchCampsitesResults> {
    const params = new URLSearchParams()
    for (const param of parseSearchFiltersToUrl(filters)) {
      params.append(param.filterName, param.filterValue)
    }
    if (params.get('bounds')) {
      // remove bounds if path
      if (params.get('path')) {
        params.delete('bounds')
      } else {
        // only full bookable if bounds and no path
        params.append('bookable', '1')
      }
    }
    if (searchUrl) params.append('search_url', searchUrl)
    const gtmLogRequest = useGtmLogRequest(
      'FetchCampsiteSearchService-search',
      logger,
    )
    gtmLogRequest.markStart()
    const url = getLangFallbackUrl(
      langCode,
      `/search2/postgres-nonprice/?${params.toString()}`,
    )
    const response = await $fetch(url, {
      ...this.fetchOptions,
      ...fetchOptions,
    })
    gtmLogRequest.markEnd()
    return {
      campsites: this.formatResults(keysToCamel(response.results)),
      pagination: {
        currentPage: Number(response.page),
        totalPages: response.num_pages,
        totalCount: response.count,
      },
      sortOption: response.sort || response.sort_field,
      ...this.formatNonPriceResultsCounts(response),
      ...keysToCamel(response, ['facet_hierarchy']),
      category: response.category,
      facetModels: response.facet_models,
    }
  }

  private formatResults(results: any[]): CampsiteSearchResult[] {
    return results.map((campsite) =>
      campsiteSearchResultSchema.parse({
        ...campsite,
        id: campsite.id.toString(),
        availableToSearch:
          typeof campsite.availableToSearch === 'undefined'
            ? campsite.bookable
            : campsite.availableToSearch,
        status: campsite.bookable ? 'bookable' : 'free',
        hierarchyPath: campsite.path,
        hierarchyStringShort: campsite.hierarchyTextShort,
        hierarchyStringShortCountry: campsite.hierarchyTextShortCountry,
        primaryPhoto: campsite.primaryPhoto
          ? this.mapPrimaryPhoto(campsite.primaryPhoto)
          : undefined,
        rating: Number(campsite.rating),
        rating10: campsite.rating * 2,
        leadPrice: campsite.cheapestLeadPrice
          ? campsite.cheapestLeadPrice[0]
          : undefined,
        leadPriceNights: campsite.cheapestLeadPrice
          ? campsite.cheapestLeadPrice[1]
          : undefined,
        leadPriceAdults: campsite.cheapestLeadPrice
          ? campsite.cheapestLeadPrice[2] ||
            Number(process.env.defaultAdultsCount)
          : undefined,
        point: getWKTPointFromGeoPoint(
          getPointFromStringOrDefault(campsite.point),
        ),
        nutshells: campsite.nutshells || [],
        firstAvailableDate: campsite.firstAvailableDate || undefined,
        pitchtypes: this.mapPitchtypes(campsite),
      }),
    )
  }

  private mapPitchtypes(campsite) {
    const pitchtypes = campsite.pitchtypes
    return pitchtypes.map((pitchtype) => ({
      ...pitchtype,
      categories: pitchtype.categories.map((category) => ({
        ...category,
        id: category.id.toString(),
      })),
      leadPrice: pitchtype.leadPrice
        ? {
          currency: campsite.currency,
          amount: pitchtype.leadPrice,
          amountMinor: parseInt((pitchtype.leadPrice * 100).toFixed(0)),
        }
        : null,
    }))
  }

  private mapPrimaryPhoto(photo: Photo) {
    return {
      caption: photo.caption,
      url: {
        ...getTransformObject<Omit<PhotoTransforms, 'masterImage'>>(
          'campsitePrimaryPhotoMaster',
          photo.url.masterImage,
        ),
        masterImage: photo.url.masterImage,
      },
    }
  }

  private formatNonPriceResultsCounts(results: {
    bookable_count: number
    facet_category: Record<string, number>
    facet_keywords: Record<string, number>
    facet_hierarchy: Record<string, number>
  }) {
    return {
      bookableCount: results.bookable_count,
      categoriesCounts: results.facet_category,
      hierarchyCounts: this.getHierarchiesFromSearchResults(results),
      keywordsCounts: results.facet_keywords,
    }
  }

  private getHierarchiesFromSearchResults(results: {
    facet_hierarchy: Record<string, number>
  }) {
    const hierarchies = {}
    const hierarchiesToCover = ['facet_hierarchy']
    for (const hierarchy of hierarchiesToCover) {
      for (const [key, value] of Object.entries(results[hierarchy])) {
        hierarchies[key] = value
      }
    }
    return hierarchies
  }
}
