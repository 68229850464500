import { SearchContext, SearchFilters } from '../schemas'
import { SearchEngineState } from '../types'
import { SearchMachineInterpreter, SearchState } from './machine'
export class SearchEngine {
  constructor(private machine: SearchMachineInterpreter) {}

  getMachine() {
    return this.machine
  }

  getContext() {
    return this.machine.state.context
  }

  search(filters: Partial<SearchFilters>, searchUrl?: string) {
    const searchFilters = this.getDefaultFilters(filters)
    const type = this.getSearchType(searchFilters)
    this.machine.send({ type, filters: searchFilters, searchUrl })
  }

  private getDefaultFilters(filters: SearchFilters) {
    return {
      ...filters,
      categoryIds: filters.categoryIds || [],
      facets: filters.facets || [],
    }
  }

  private getSearchType(filters: SearchFilters) {
    if (filters.dates) {
      return 'priceSearch'
    }
    return 'search'
  }

  get status() {
    return this.machine.state.toStrings()[0]
  }

  onContext(listener: (context: SearchContext) => void) {
    this.machine.onTransition((state) => {
      listener(state.context)
    })
  }

  onState(listener: (state: SearchEngineState) => void) {
    this.machine.onTransition((state) => {
      const stateName = this.getCurrentStateName(state)
      listener(stateName)
    })
  }

  private getCurrentStateName(state: SearchState): SearchEngineState {
    if (state.matches('searching') || state.matches('priceSearching')) {
      return 'searching'
    }
    if (state.matches('results')) {
      return 'results'
    }
    if (state.matches('redirecting')) {
      return 'redirecting'
    }
    if (state.matches('error')) {
      return 'error'
    }
    return 'idle'
  }
}
