import { parseSearchFiltersToUrl } from '~/apps/pu-links/domain/SearchUrl'
import { getLangFallbackUrl } from '~/lang/utils/langfetch'
import { getTransformObject } from '~/shared/photos/transforms'
import { getWKTPointFromGeoPoint } from '~/utility/geo/GeoLocation'
import { getPointFromStringOrDefault } from '~/utility/geo/latLng'
import { logger as logger2 } from '~/utility/logger'
import { keysToCamel } from '~/utility/toCamel'
import { Logger, useGtmLogRequest } from '../../../composables/useGtmLogRequest'
import {
  CampsitePriceSearchResult,
  Photo,
  PhotoTransforms,
  SearchFilters,
  campsitePriceSearchResultSchema,
} from '../schemas'

export class FetchCampsitePriceSearchService {
  constructor(private fetchOptions?: any) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch(url: string, fetchOptions?: any): Promise<any> {
    return $fetch<any>(url, { ...this.fetchOptions, ...fetchOptions })
  }

  async search(
    langCode: string,
    filters: SearchFilters,
    logger: Logger,
    fetchOptions?: any,
  ) {
    logger2('priceSearch', filters)
    const params = this.filtersToParams(filters)
    if (filters.engine) {
      params.append('engine', filters.engine.toString())
    }
    const gtmLogRequest = useGtmLogRequest(
      'FetchCampsitePriceSearchService-search',
      logger,
    )
    gtmLogRequest.markStart()
    const response = await this.fetch(
      getLangFallbackUrl(langCode, `/search2/price/?${params.toString()}`),
      fetchOptions,
    )
    gtmLogRequest.markEnd()
    return {
      campsites: this.formatResults(keysToCamel(response.results)),
      pagination: {
        currentPage: Number(response.page),
        totalPages: response.num_pages,
        totalCount: response.count,
      },
      title: response.title,
      heading: response.heading,
      description: response.description,
      hierarchyTrail: response.hierarchy_trail,
      bookable: response.bookable,
      bookableCount: response.count,
      category: response.category,
      count: response.count,
      path: response.path,
      leadPrice: response.lead_price,
      sortOptions: response.sort_options,
      sortOption: response.sort || response.sort_field,
      maxPrice: response.result_max_price,
      within: response.within,
      breadcrumbs: response.breadcrumbs || [],
      facetHierarchy: response.facet_hierarchy || {},
      categoriesCounts: response.facet_category || {},
      hierarchyCounts: response.facet_hierarchy || {},
      keywordsCounts: response.facet_keywords || {},
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatResults(results: any[]): CampsitePriceSearchResult[] {
    return results.map((campsite) =>
      campsitePriceSearchResultSchema.parse({
        ...campsite,
        id: campsite.campsiteId.toString(),
        availableToSearch: true,
        status: 'bookable',
        hierarchyPath: campsite.path,
        hierarchyStringShort: campsite.hierarchyTextShort,
        hierarchyStringShortCountry: campsite.hierarchyTextShortCountry,
        primaryPhoto: campsite.primaryPhoto
          ? this.mapPrimaryPhoto(campsite.primaryPhoto)
          : undefined,
        rating: Number(campsite.rating),
        rating10: Number(campsite.rating) * 2,
        point: campsite.point
          ? getWKTPointFromGeoPoint(getPointFromStringOrDefault(campsite.point))
          : undefined,
        nutshells: campsite.nutshells,
        distance:
          campsite.distance && Object.keys(campsite.distance).length
            ? campsite.distance
            : undefined,
      }),
    )
  }

  private mapPrimaryPhoto(photo: Photo) {
    return {
      caption: photo.caption,
      url: {
        ...getTransformObject<Omit<PhotoTransforms, 'masterImage'>>(
          'campsitePrimaryPhotoMaster',
          photo.url.masterImage,
        ),
        masterImage: photo.url.masterImage,
      },
    }
  }

  // tslint:disable-next-line cognitive-complexity
  private filtersToParams(filters: SearchFilters) {
    if (!filters.dates) {
      throw new Error('no dates')
    }
    const params = new URLSearchParams()
    for (const param of parseSearchFiltersToUrl(filters)) {
      params.append(param.filterName, param.filterValue)
    }
    if (params.get('bounds')) {
      // remove bounds if path
      if (params.get('path')) {
        params.delete('bounds')
      }
    }
    return params
  }
}
