export const defaultSearchParty = {
  adults: 2,
  childAges: [],
}

export const defaultPageSize = 20

export function getDefaultSearchFilters() {
  return {
    base: undefined,
    bounds: undefined,
    dates: undefined,
    hierarchyPath: undefined,
    placename: undefined,
    q: undefined,
    sort: undefined,
    categoryIds: [],
    facets: [],
    page: undefined,
    party: undefined,
    limit: defaultPageSize,
    cleared: false,
  }
}
