function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
import { parseSearchFiltersToUrl } from '~/apps/pu-links/domain/SearchUrl';
import { langFallback$Fetch } from '../../../lang/utils/langfetch';
var NON_PRICE_SEARCH_ENDPOINT = '/search2/facets';
var PRICE_SEARCH_ENDPOINT = '/search2/facets';
export class SearchCountsService {
  fetchPriceSearchCounts(langCode, searchFilters, logger) {
    var _this = this;
    return _asyncToGenerator(function* () {
      var params = _this.getQueryString(searchFilters);
      var url = "".concat(PRICE_SEARCH_ENDPOINT, "/?").concat(params);
      var gtmLogRequest = useGtmLogRequest('SearchCountsService-fetchPriceSearchCounts', logger);
      gtmLogRequest.markStart();
      var response = yield langFallback$Fetch(langCode, url);
      gtmLogRequest.markEnd();
      return _this.formatPriceResults(response);
    })();
  }
  fetchNonPriceSearchCounts(langCode, searchFilters, logger) {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      var params = searchFilters ? "&".concat(_this2.getQueryString(searchFilters)) : '';
      var url = "".concat(NON_PRICE_SEARCH_ENDPOINT, "/?").concat(params, "&seo=none&sort=none&counts=1");
      var gtmLogRequest = useGtmLogRequest('SearchCountsService-fetchNonPriceSearchCounts', logger);
      gtmLogRequest.markStart();
      var response = yield langFallback$Fetch(langCode, url);
      gtmLogRequest.markEnd();
      return _this2.formatNonPriceResults(response);
    })();
  }
  getQueryString(searchFilters) {
    return this.filtersToParams(searchFilters).toString();
  }
  filtersToParams(filters) {
    var params = new URLSearchParams();
    for (var param of parseSearchFiltersToUrl(filters)) {
      params.append(param.filterName, param.filterValue);
    }
    if (params.get('bounds')) {
      // remove bounds if path
      if (params.get('path')) {
        params.delete('bounds');
      } else {
        // only full bookable if bounds and no path
        params.append('bookable', '1');
      }
    }
    return params;
  }
  formatNonPriceResults(results) {
    return {
      bookableCount: results.bookable_count,
      categoriesCounts: results.facet_category,
      hierarchyCounts: this.getHierarchiesFromSearchResults(results),
      keywordsCounts: results.facet_keywords
    };
  }
  getHierarchiesFromSearchResults(results) {
    var hierarchies = {};
    if (!(results !== null && results !== void 0 && results.facet_hierarchy)) return hierarchies;
    for (var [key, value] of Object.entries(results.facet_hierarchy)) {
      hierarchies[key] = value;
    }
    return hierarchies;
  }
  formatPriceResults(results) {
    return _objectSpread({
      bookableCount: results.facet_all,
      categoriesCounts: results.facet_category,
      hierarchyCounts: results.facet_hierarchy,
      keywordsCounts: results.facet_keywords
    }, results);
  }
}
export function getSearchCountService() {
  return new SearchCountsService();
}